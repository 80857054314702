<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <div v-if="!tableItem.isDownloading">
      <div
        v-for="(iconAction, i) in iconsAction"
        v-show="
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'Clone' && tableItem.isDeployable) ||
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'DisabledClone' && !tableItem.isDeployable) ||
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'DisabledExport' && !tableItem.isDeployable) ||
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'Export' && tableItem.isDeployable) ||
          (canAccess('UI_WORKLOAD:VERSION_DELETE') &&
            iconAction === 'Delete' &&
            ((!tableItem.released && tableItem.isDeployable) ||
              (tableItem.released && !tableItem.isDeployable) ||
              (!tableItem.released && !tableItem.isDeployable))) ||
          (canAccess('UI_WORKLOAD:VERSION_DELETE') &&
            iconAction === 'DisabledDelete' &&
            tableItem.released &&
            tableItem.isDeployable) ||
          (canAccess('UI_WORKLOAD:VERSION_DELETE') && iconAction === 'DisabledCancelCreation') ||
          (canAccess('UI_DEPLOY:DEPLOY') && iconAction === 'Deploy' && tableItem.isDeployable && !workload.disabled) ||
          (canAccess('UI_DEPLOY:DEPLOY') &&
            iconAction === 'DisabledDeploy' &&
            (!tableItem.isDeployable || workload.disabled)) ||
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'Copy' && tableItem.isDeployable) ||
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'DisabledCopy' && !tableItem.isDeployable)
        "
        :key="i"
        class="inline"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-img
              :id="`iiotWorkloadDetailTableActions${iconAction}Image_${index}`"
              :src="`/img/${iconAction}.svg`"
              v-bind="attrs"
              max-height="40px"
              max-width="40px"
              v-on="on"
              @click.native.stop="clickAction(iconAction)"
            />
          </template>
          <span>{{ $t(`defaultTable.${iconAction}`) }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-if="tableItem.isDownloading">
      <div
        v-for="(iconAction, i) in iconsAction"
        v-show="
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'DisabledClone') ||
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'DisabledExport') ||
          (canAccess('UI_WORKLOAD:VERSION_DELETE') && iconAction === 'DisabledDelete') ||
          (canAccess('UI_WORKLOAD:VERSION_DELETE') && iconAction === 'CancelCreation') ||
          (canAccess('UI_DEPLOY:DEPLOY') && iconAction === 'DisabledDeploy') ||
          (canAccess('UI_WORKLOAD:VERSION_CREATE') && iconAction === 'DisabledCopy')
        "
        :key="i"
        class="inline"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-img
              :id="`iiotWorkloadDetailTableActions${iconAction}Image_${index}`"
              :src="`/img/${iconAction}.svg`"
              v-bind="attrs"
              max-height="40px"
              max-width="40px"
              v-on="on"
              @click.native.stop="clickAction(iconAction)"
            />
          </template>
          <span>{{ $t(`defaultTable.${iconAction}`) }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable no-alert -->
<!-- eslint-disable consistent-return -->
<script>
import { WorkloadsApiService } from '@/services/api';

export default {
  props: {
    iconsAction: {
      type: Array,
      default: () => [],
    },
    fetchActionUpdate: {
      type: Function,
      default: () => {},
    },
    fetchActionClone: {
      type: Function,
      default: () => {},
    },
    canceledCreation: {
      type: Function,
      default: () => {},
    },
    workloadId: {
      type: String,
      default: '',
    },
    workload: {
      type: Object,
      default: () => ({}),
    },
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    uniqueInfo() {
      return this.$store.getters['workloads/uniqueInfo'];
    },
  },

  methods: {
    // eslint-disable-next-line consistent-return
    async clickAction(iconAction) {
      if (iconAction === 'Delete') {
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadDetail.dialog.title',
          subTitle: 'workloadDetail.dialog.subTitle',
          callback: async () => {
            try {
              await this.$store.dispatch('workloads/delete_version', {
                type: this.workload.type,
                wlId: this.workloadId,
                verId: this.tableItem._id,
              });
            } catch (e) {
              this.$log.debug(e);
            }
          },
        });
      }
      if (iconAction === 'Clone') {
        this.fetchActionClone()(this.tableItem);
      }
      if (iconAction === 'Export') {
        if (this.workload.type === 'docker-compose') {
          try {
            const exportUrl = `${window.location.pathname.split('/')[0]}/nerve/v3/workloads/${
              this.workloadId
            }/versions/${this.tableItem.id}/export`;
            window.location = exportUrl;
          } catch (e) {
            return e;
          }
        } else {
          WorkloadsApiService.checkFiles({
            wlId: this.workloadId,
            verId: this.tableItem.id,
            // eslint-disable-next-line consistent-return
          }).then(async () => {
            try {
              const exportUrl = `${window.location.pathname.split('/')[0]}/nerve/v2/workload/${this.workloadId}/${
                this.tableItem.id
              }`;
              window.location = exportUrl;
            } catch (e) {
              return e;
            }
          });
        }
      }
      if (iconAction === 'CancelCreation') {
        try {
          if (this.workload.type === 'docker-compose') {
            await this.$store.dispatch('workloads/cancel_compose_version_creation', {
              wlId: this.workloadId,
              verId: this.tableItem.id,
            });
            this.$store.dispatch('workloads/update_workload_error_messages', {
              error: 'nerve_workload_files_cancelled',
              version: this.tableItem.id,
            });
            return;
          }
          WorkloadsApiService.canceledVersionCreation({
            wlId: this.workloadId,
            verId: this.tableItem.id,
          });
        } catch (e) {
          return e;
        }
      }
      if (iconAction === 'Deploy') {
        try {
          this.$router.push({
            name: 'Deploy Form Step Two',
            params: {
              type: this.workload.type,
              workloadId: this.workloadId,
              versionId: this.tableItem.id,
              origin: 'from-workload',
            },
          });
        } catch (e) {
          return e;
        }
      }
      if (iconAction === 'Copy') {
        try {
          await this.$store.dispatch('workloads/unique_info', {
            workloadId: this.workloadId,
            versionId: this.tableItem._id,
          });
          if (!this.uniqueInfo.name || !this.uniqueInfo.version || !this.uniqueInfo.hash) {
            await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
              text: 'workloadVersion.uniqueInfoError',
              color: 'red',
              showClose: true,
            });
            return null;
          }
          navigator.clipboard.writeText(
            `name: ${this.uniqueInfo.name}\nversion: ${this.uniqueInfo.version}\nhash: ${this.uniqueInfo.hash}`,
          );
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'workloadVersion.uniqueInfo',
            color: 'primary',
            showClose: true,
          });
        } catch (e) {
          return e;
        }
      }
    },
  },
};
</script>

<style scoped>
.inline {
  display: inline-block;
}
</style>
